import { Dialog } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface QRDialogProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function QRDialog({ isOpen, onClose }: QRDialogProps) {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      //   sx={{ position: "fixed", transform: "translate(-50%, -50%)" }}
    >
      <div className="bg-[#fff7f0] flex flex-row justify-center w-full">
        <div className="bg-[#fff7f0] w-[327px] h-[386px] relative">
          <div className="!absolute !top-3 !left-[291px]" onClick={onClose}>
            <CloseRoundedIcon
              className=" !w-6 !h-6 cursor-pointer"
              sx={{ color: "#67695B" }}
            />
          </div>
          <img
            className="absolute w-40 h-40 top-[86px] left-[84px]"
            alt="QR"
            src="/assets/images/qr.webp"
          />

          <div className="absolute top-[31px] left-[94px] [font-family:'Alex_Brush',Helvetica] font-normal text-[#67695b] text-[32px] text-center tracking-[1.28px] leading-[normal]">
            Xin cảm ơn!
          </div>

          <div className="flex flex-col w-[143px] items-center gap-3 absolute top-[262px] left-[92px]">
            <img
              className="relative w-[72px] h-6 object-cover"
              alt="Image"
              src="https://c.animaapp.com/xZTcSAdb/img/image-24@2x.png"
            />

            <div className="inline-flex flex-col items-start gap-1.5 relative flex-[0_0_auto]">
              <div className="relative w-[143px] mt-[-1.00px] [font-family:'Noto_Serif',Helvetica] font-bold text-[#67695b] text-sm text-center tracking-[1.40px] leading-[normal]">
                Trần Tuấn Lâm
              </div>

              <div className="relative w-[143px] [font-family:'Noto_Serif',Helvetica] font-bold text-[#67695b] text-sm text-center tracking-[2.80px] leading-[normal]">
                0011004436207
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
