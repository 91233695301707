/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useState } from "react";
import "./App.css";
import MainLayout from "./components/MainLayout";
import QRDialog from "./components/QRDialog";
import { motion } from "framer-motion";

function App() {
  const [formData, setFormData] = useState<{
    name: string;
    isAttending: boolean;
    isGroomGuest: boolean | undefined;
  }>({
    name: "",
    isAttending: true,
    isGroomGuest: undefined,
  });
  const [submitStatus, setSubmitStatus] = useState("");
  const [isOpenQrDialog, setIsOpenQrDialog] = useState(false);

  const handleSubmitForm = async () => {
    if (submitStatus === "success") {
      return;
    }

    try {
      setSubmitStatus("pending");
      const payload = new FormData();
      payload.append("data[name]", formData.name || "unknown");
      payload.append(
        "data[attendance]",
        formData.isAttending ? "attending" : "not attending"
      );
      payload.append(
        "data[guest_of]",
        typeof formData.isGroomGuest === "boolean"
          ? formData.isGroomGuest
            ? "groom"
            : "bride"
          : "unknown"
      );

      const rs = await fetch("https://sheetdb.io/api/v1/i35aosjjbxqdj", {
        method: "POST",
        body: payload,
      });

      if (rs.status === 201) {
        setSubmitStatus("success");
      } else {
        setSubmitStatus("fail");
      }
    } catch (ex) {
      setSubmitStatus("fail");
    }
  };

  return (
    <Fragment>
      <QRDialog
        isOpen={isOpenQrDialog}
        onClose={() => setIsOpenQrDialog(false)}
      />
      <MainLayout>
        <div className="relative w-full h-[4392px] bg-[#ede1d5] overflow-hidden">
          <div className="absolute w-[343px] h-[77px] top-[596px] right-1/2 translate-x-1/2">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="absolute w-[343px] -top-px left-0 [font-family:'Noto_Serif',Helvetica] font-normal text-[#67695b] text-xl text-center tracking-[2.00px] leading-[normal]"
            >
              WE’RE GETTING MARRIED!
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.2, delay: 0.8 }}
              className="absolute w-[343px] top-9 left-0 [font-family:'Noto_Serif',Helvetica] font-normal text-[#67695b] text-[10px] text-center tracking-[1.00px] leading-5"
            >
              “Những điều đẹp đẽ sẽ không thể nhìn thấy bằng mắt thường, mà phải
              cảm nhận bằng trái tim”
            </motion.div>
          </div>

          <div className="absolute w-full top-[1419px] right-1/2 translate-x-1/2 [font-family:'Noto_Serif',Helvetica] font-normal text-[#67695b] text-sm text-center tracking-[1.40px] leading-[normal]">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5 }}
            >
              Thân mời
            </motion.div>
          </div>

          <div className="absolute w-full h-[632px] top-[726px] left-0">
            <div className="absolute w-full h-[280px] top-0 left-0">
              <img
                className="left-0 object-cover absolute w-1/2 h-[280px] top-0"
                alt="Beo"
                src="https://c.animaapp.com/D4mYxIxt/img/-beo5037-1-2@2x.png"
              />

              <div className="absolute w-1/2 h-[280px] top-0 right-0">
                <div className="relative w-full h-[280px] bg-[#67695b]">
                  <div className="absolute w-full h-[170px] right-1/2 translate-x-1/2 top-[46px]">
                    <img
                      className="object-cover absolute w-2/3 h-[170px] right-1/2 translate-x-1/2 top-1/2 -translate-y-1/2"
                      alt="Beo"
                      src="https://c.animaapp.com/D4mYxIxt/img/-beo5750-1-2@2x.png"
                    />

                    <div className="absolute -top-3 left-[6%] font-extralight text-sm tracking-[1.68px] [font-family:'Noto_Serif',Helvetica] text-[#f4efe9] text-center leading-[normal]">
                      <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 2 }}
                      >
                        CÔ DÂU
                      </motion.div>
                    </div>
                  </div>

                  <div className="absolute w-full top-[229px] right-1/2 translate-x-1/2 [font-family:'Alex_Brush',Helvetica] font-normal text-[#f4efe9] text-xl text-center tracking-[0.80px] leading-[normal]">
                    <motion.div
                      initial={{ opacity: 0, x: 20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 2 }}
                    >
                      Trần Phương Anh
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>

            <div className="absolute w-full h-[280px] top-[352px] left-0">
              <img
                className="right-0 absolute w-1/2 object-cover h-[280px] top-0"
                alt="Beo"
                src="https://c.animaapp.com/D4mYxIxt/img/-beo5037-1-3@2x.png"
              />

              <div className="absolute w-1/2 h-[280px] top-0 left-0-0">
                <div className="relative w-full h-[280px] bg-[#67695b]">
                  <div className="absolute w-full h-[170px] right-1/2 translate-x-1/2 top-[46px]">
                    <img
                      className="right-1/2 translate-x-1/2 top-1/2 -translate-y-1/2 absolute w-2/3 h-[170px] object-cover"
                      alt="Beo"
                      src="https://c.animaapp.com/D4mYxIxt/img/-beo5750-1-3@2x.png"
                    />

                    <div className="absolute -top-3 right-[6%] font-extralight text-sm tracking-[1.68px] [font-family:'Noto_Serif',Helvetica] text-[#f4efe9] text-center leading-[normal]">
                      <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 2 }}
                      >
                        CHÚ RỂ
                      </motion.div>
                    </div>
                  </div>

                  <div className="absolute w-full top-[229px] right-1/2 translate-x-1/2 [font-family:'Alex_Brush',Helvetica] font-normal text-[#f4efe9] text-xl text-center tracking-[0.80px] leading-[normal]">
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 2 }}
                    >
                      Trần Tuấn Lâm
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>

            <img
              className="top-[260px] right-1/2 translate-x-1/2 absolute w-[122px] h-[121px] object-cover"
              alt="Decorate flower"
              src="https://c.animaapp.com/D4mYxIxt/img/decorate-flower-1-2@2x.png"
            />
          </div>

          <div className="absolute w-full h-[578px] top-0 flex flex-col">
            <img
              className="absolute w-full h-[564px] top-0 object-cover mobile:hidden block"
              alt="Beo"
              src="/assets/images/corver_bg_1_3x_mobile.webp"
            />
            <img
              className="absolute w-full h-[564px] top-0 object-cover hidden mobile:block"
              alt="Beo"
              src="/assets/images/corver_bg_1_3x.webp"
            />

            {/* <div
            className="absolute w-full h-[260px] top-[317px] left-0 bg-[#443d214c] rounded-[233px/130.5px]"
            style={{ filter: "blur(100px)" }}
          /> */}

            <motion.div
              initial={{ opacity: 0, y: 20, x: 1 / 2 }}
              whileInView={{ opacity: 1, y: 0, x: 1 / 2 }}
              transition={{ duration: 1 }}
              className="inline-flex items-center justify-center gap-5 absolute top-7 w-full"
            >
              <div className="relative w-fit mt-[-1.00px] [font-family:'Bodoni_Moda',Helvetica] font-normal text-[#ede1d5] text-4xl text-right tracking-[1.44px] leading-[normal]">
                SAVE
              </div>

              <div className="relative w-9 h-[38px]">
                <div className="absolute w-12 -top-1.5 left-0 [font-family:'Lavishly_Yours',Helvetica] font-normal text-[#ede1d5] text-4xl tracking-[1.44px] leading-10">
                  our
                </div>
              </div>

              <div className="relative w-fit mt-[-1.00px] [font-family:'Bodoni_Moda',Helvetica] font-normal text-[#ede1d5] text-4xl text-right tracking-[1.44px] leading-[normal]">
                DATE
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="absolute top-0 w-full"
            >
              <div className="w-[375px] absolute right-1/2 translate-x-1/2">
                <div className="absolute top-[395px] right-1/2 translate-x-1/2 [font-family:'Bodoni_Moda',Helvetica] font-normal text-[#ede1d5] text-base text-right tracking-[0.64px] leading-[normal]">
                  &amp;
                </div>

                <div className="absolute top-[357px] left-[46px] [font-family:'Cormorant_Upright',Helvetica] font-semibold text-[#ede1d5] text-[28px] text-center tracking-[2.80px] leading-[normal]">
                  TUẤN LÂM
                </div>

                <div className="absolute top-[424px] right-[46px] [font-family:'Cormorant_Upright',Helvetica] font-semibold text-[#ede1d5] text-[28px] text-center tracking-[2.80px] leading-[normal]">
                  PHƯƠNG ANH
                </div>
              </div>
            </motion.div>
          </div>

          <div className="absolute w-20 h-px top-[697px] right-1/2 translate-x-1/2 bg-[#67695b]" />

          <div className="absolute w-20 h-px top-[1399px] right-1/2 translate-x-1/2 bg-[#67695b]" />

          <div className="absolute w-20 h-px top-[3493px] right-1/2 translate-x-1/2 bg-[#67695b]" />

          <p className="absolute top-[1446px] w-full right-1/2 translate-x-1/2 [font-family:'Noto_Serif',Helvetica] font-normal text-[#67695b] text-base text-center tracking-[0] leading-[normal]">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              ĐẾN DỰ LỄ CƯỚI CỦA CHÚNG TÔI
            </motion.div>
          </p>

          <div className="absolute w-full h-[534px] top-[2255px] left-0">
            <div className="absolute w-full h-[534px] top-0 bg-[#67695b]">
              <div className="absolute w-[246px] h-[478px] right-1/2 translate-x-1/2 top-[70px]">
                <img
                  className="absolute w-[50px] h-[50px] top-0.5 left-[3px] object-cover"
                  alt="Video"
                  src="https://c.animaapp.com/D4mYxIxt/img/video-10941645-1-1@2x.png"
                />

                <div className="absolute w-[246px] h-[478px] top-0 left-0">
                  <div className="w-[76px] gap-8 absolute top-0 left-[170px] flex flex-col items-center">
                    <div className="w-[65px] gap-1.5 relative flex-[0_0_auto] flex flex-col items-center">
                      <div className="relative self-stretch mt-[-1.00px] text-[#f4efe9] [font-family:'Noto_Serif',Helvetica] font-normal text-sm text-center tracking-[2.52px] leading-[normal]">
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1, delay: 0 }}
                        >
                          17:00
                        </motion.div>
                      </div>

                      <div className="relative w-max ml-[-20.00px] mr-[-20.00px] [font-family:'Noto_Serif',Helvetica] font-normal text-[#f4efe9] text-base text-center tracking-[2.88px] leading-[normal]">
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1.2, delay: 0 }}
                        >
                          Đón khách
                        </motion.div>
                      </div>
                    </div>

                    <div className="flex flex-col w-[65px] items-center gap-1.5 relative flex-[0_0_auto]">
                      <div className="relative self-stretch mt-[-1.00px] [font-family:'Noto_Serif',Helvetica] font-normal text-[#f4efe9] text-sm text-center tracking-[2.52px] leading-[normal]">
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1, delay: 1.5 }}
                        >
                          18:00
                        </motion.div>
                      </div>

                      <div className="relative w-max ml-[-4.50px] mr-[-4.50px] [font-family:'Noto_Serif',Helvetica] font-normal text-[#f4efe9] text-base text-center tracking-[2.88px] leading-[normal]">
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1.2, delay: 1.5 }}
                        >
                          Lễ cưới
                        </motion.div>
                      </div>
                    </div>

                    <div className="flex flex-col items-center gap-1.5 relative self-stretch w-full flex-[0_0_auto]">
                      <div className="relative self-stretch mt-[-1.00px] [font-family:'Noto_Serif',Helvetica] font-normal text-[#f4efe9] text-base text-center tracking-[2.88px] leading-[normal]">
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1, delay: 3 }}
                        >
                          18:30
                        </motion.div>
                      </div>

                      <div className="relative w-max self-stretch [font-family:'Noto_Serif',Helvetica] font-normal text-[#f4efe9] text-base text-center tracking-[2.88px] leading-[normal]">
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1.2, delay: 3 }}
                        >
                          Tiệc tối
                        </motion.div>
                      </div>
                    </div>

                    <div className="flex flex-col w-[65px] items-center gap-1.5 relative flex-[0_0_auto]">
                      <div className="relative self-stretch mt-[-1.00px] [font-family:'Noto_Serif',Helvetica] font-normal text-[#f4efe9] text-sm text-center tracking-[2.52px] leading-[normal]">
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1, delay: 4.5 }}
                        >
                          19:30
                        </motion.div>
                      </div>

                      <div className="relative w-max ml-[-24.00px] mr-[-24.00px] [font-family:'Noto_Serif',Helvetica] font-normal text-[#f4efe9] text-base text-center tracking-[2.88px] leading-[normal]">
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1.2, delay: 4.5 }}
                        >
                          After Party
                        </motion.div>
                      </div>
                    </div>

                    <div className="flex flex-col w-[65px] items-center gap-1.5 relative flex-[0_0_auto]">
                      <div className="relative self-stretch mt-[-1.00px] [font-family:'Noto_Serif',Helvetica] font-normal text-[#f4efe9] text-sm text-center tracking-[2.52px] leading-[normal]">
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1, delay: 6 }}
                        >
                          20:00
                        </motion.div>
                      </div>

                      <div className="relative w-max ml-[-13.00px] mr-[-13.00px] [font-family:'Noto_Serif',Helvetica] font-normal text-[#f4efe9] text-base text-center tracking-[2.88px] leading-[normal]">
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1.2, delay: 6 }}
                        >
                          Pháo hoa
                        </motion.div>
                      </div>
                    </div>
                  </div>

                  <div className="w-[306px] top-[178px] -left-12 bg-[#f4efe9] absolute h-px rotate-[90.00deg]" />

                  <img
                    className="absolute w-[50px] h-[50px] top-[158px] left-0 object-cover"
                    alt="Image15"
                    src="https://c.animaapp.com/D4mYxIxt/img/image-15-1@2x.png"
                  />

                  <img
                    className="top-[357px] right-1/2 translate-x-1/2 absolute w-[122px] h-[121px] object-cover"
                    alt="Decorate flower"
                    src="https://c.animaapp.com/D4mYxIxt/img/decorate-flower-1-3@2x.png"
                  />
                </div>

                <div className="absolute w-1.5 h-1.5 top-5 left-[102px] bg-[#f4efe9] rounded-[1px] rotate-45" />

                <div className="absolute w-1.5 h-1.5 top-[99px] left-[102px] bg-[#f4efe9] rounded-[1px] rotate-45" />

                <div className="absolute w-1.5 h-1.5 top-[180px] left-[102px] bg-[#f4efe9] rounded-[1px] rotate-45" />

                <div className="absolute w-1.5 h-1.5 top-64 left-[102px] bg-[#f4efe9] rounded-[1px] rotate-45" />

                <div className="absolute w-1.5 h-1.5 top-[331px] left-[102px] bg-[#f4efe9] rounded-[1px] rotate-45" />

                <img
                  className="absolute w-[50px] h-[57px] top-[230px] left-0 object-cover"
                  alt="Image2"
                  src="https://c.animaapp.com/D4mYxIxt/img/image-2-1@2x.png"
                />

                <img
                  className="absolute w-[57px] h-[50px] top-[77px] left-0"
                  alt="Timeline"
                  src="https://c.animaapp.com/D4mYxIxt/img/timeline-1-1@2x.png"
                />

                <img
                  className="absolute w-[50px] h-[50px] top-[309px] left-0 object-cover"
                  alt="Fireworks"
                  src="https://c.animaapp.com/D4mYxIxt/img/fireworks-389608-1-1@2x.png"
                />
              </div>

              <div className="absolute top-[19px] right-1/2 translate-x-1/2 [font-family:'Noto_Serif',Helvetica] font-normal text-[#f4efe94c] text-2xl text-center tracking-[4.80px] leading-[normal]">
                TIMELINE
              </div>
            </div>
          </div>

          <div className="absolute top-[2839px] w-full px-6 flex flex-col">
            <div className="flex space-x-4 items-center">
              <div className="w-full flex-auto h-auto">
                <div className="h-px w-full bg-[#67695b] mt-[17px] mb-[4.5px]" />
              </div>

              <div className="w-max flex-none [font-family:'Noto_Serif',Helvetica] font-normal text-[#67695b] text-base text-center tracking-[3.20px] leading-[normal]">
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1.2, delay: 0 }}
                >
                  ALBUM ẢNH CƯỚI
                </motion.div>
              </div>
            </div>
            <div className="w-full h-[508px] relative mt-5">
              <img
                className="absolute w-1/2 h-[180px] top-0 right-0 object-cover"
                alt="Beo"
                src="https://c.animaapp.com/D4mYxIxt/img/-beo5750-2-1@2x.png"
              />

              <img
                className="absolute w-1/2 h-[104px] top-[184px] right-0 object-cover"
                alt="Beo"
                src="https://c.animaapp.com/D4mYxIxt/img/-beo5750-3-1@2x.png"
              />

              <div className="absolute w-1/2 right-0 h-[104px] top-[404px] card-zoom overflow-hidden">
                <div className="h-full card-zoom-content j-overflow-hidden">
                  <img
                    className="absolute w-full h-full transition-all duration-1000 ease-in-out transform bg-center bg-cover object-cover card-zoom-image"
                    alt="Beo"
                    src="https://c.animaapp.com/D4mYxIxt/img/-beo5750-4-1@2x.png"
                  />
                </div>
              </div>

              <div className="absolute w-1/2 h-[104px] top-[404px] left-0 card-zoom overflow-hidden">
                <div className="h-full card-zoom-content j-overflow-hidden">
                  <img
                    className="absolute w-full h-full transition-all duration-1000 ease-in-out transform bg-center bg-cover object-cover card-zoom-image"
                    alt="Beo"
                    src="https://c.animaapp.com/D4mYxIxt/img/-beo5750-5-1@2x.png"
                  />
                </div>
              </div>

              <div className="absolute top-[404px] right-1/2 translate-x-1/2 w-[4px] bg-[#EDE1D5] h-[104px]"></div>

              <img
                className="absolute w-1/2 h-72 top-0 left-0 object-cover"
                alt="Image7"
                src="https://c.animaapp.com/D4mYxIxt/img/image-7-1@2x.png"
              />

              <img
                className="absolute w-full h-[108px] top-[292px] left-0 object-cover"
                alt="Image6"
                src="https://c.animaapp.com/D4mYxIxt/img/image-6-1@2x.png"
              />

              <div className="absolute top-0 right-1/2 translate-x-1/2 w-[4px] bg-[#EDE1D5] h-72"></div>
            </div>
            <div className="flex mt-6 cursor-pointer w-full items-center justify-center gap-2.5 px-0 py-3 border border-solid border-[#67695b]">
              <div className="relative w-fit mt-[-1.00px] [font-family:'Noto_Serif',Helvetica] font-bold text-[#67695b] text-xs text-center tracking-[2.40px] leading-[normal]">
                XEM TẤT CẢ
              </div>
            </div>
          </div>

          <div className="absolute top-[3514px] w-max right-1/2 translate-x-1/2 [font-family:'Noto_Serif',Helvetica] font-normal text-[#67695b] text-base text-center tracking-[3.20px] leading-[normal]">
            XÁC NHẬN THAM DỰ
          </div>

          <div className="absolute w-full px-6 top-[1498px]">
            <img
              className="object-cover h-28 w-full"
              alt="Tuấn Lâm & Phương Anh"
              src="https://c.animaapp.com/D4mYxIxt/img/image-9-1@2x.png"
            />
          </div>
          <div className="absolute w-full h-[259px] top-[1650px] right-1/2 translate-x-1/2 px-6">
            <div className="mx-auto">
              <div className="grid grid-cols-2 border-b border-[#67695B]">
                <div className="text-center border-r-[0.5px] border-[#67695B]">
                  <p className="border-t-[1px] border-b-[0.5px] pt-1 pb-[5px] border-[#67695B] [font-family:'Noto_Sans_Tamil_UI-Bold',Helvetica] font-bold text-[#67695b] text-xs text-center tracking-[0.72px] leading-[normal]">
                    NGÀY
                  </p>
                  <p className="pt-[9.5px] pb-[11px] [font-family:'Noto_Serif',Helvetica] font-normal italic text-[#67695b] text-sm text-center tracking-[2.52px] leading-[normal]">
                    03 - 01 - 2025
                  </p>
                </div>
                <div className="text-center">
                  <p className="border-t-[1px] border-b-[0.5px] pt-1 pb-[5px] border-[#67695B] [font-family:'Noto_Sans_Tamil_UI-Bold',Helvetica] font-bold text-[#67695b] text-xs text-center tracking-[0.72px] leading-[normal]">
                    GIỜ
                  </p>
                  <p className="pt-[9.5px] pb-[11px] [font-family:'Noto_Serif',Helvetica] font-normal italic text-[#67695b] text-sm text-center tracking-[2.52px] leading-[normal]">
                    17:00
                  </p>
                </div>
              </div>

              <div className="text-center pt-1.5 pb-2 border-b border-[#67695B]">
                <p className="[font-family:'Noto_Serif',Helvetica] font-normal italic text-[#67695b] text-[10px] text-center tracking-[1.80px] leading-[normal]">
                  tức ngày 04 tháng 12 năm Giáp Thìn
                </p>
              </div>

              <div className="text-center pt-[13.5px] pb-4 border-b border-[#67695B] flex flex-col space-y-2">
                <p className="[font-family:'Noto_Sans_Tamil_UI-Bold',Helvetica] font-bold text-[#67695b] text-[13px] text-center tracking-[1.56px] leading-[normal]">
                  SẢNH TIỆC STAR PALACE - SEN TÂY HỒ
                </p>
                <p className="[font-family:'Noto_Serif',Helvetica] font-normal italic text-[#67695b] text-[10px] text-center tracking-[1.20px] leading-[normal]">
                  614 đường Lạc Long Quân, phường Nhật Tân, <br />
                  quận Tây Hồ, Hà Nội
                </p>
              </div>

              <div className="grid grid-cols-2 pt-[19px]">
                <div className="text-center flex flex-col space-y-[3px]">
                  <p className="[font-family:'Noto_Sans_Tamil_UI-Bold',Helvetica] font-bold text-[#67695b] text-[9px] text-center tracking-[0.54px] leading-[normal]">
                    NHÀ TRAI
                  </p>
                  <p className="[font-family:'Noto_Serif',Helvetica] font-normal italic text-[#67695b] text-[10px] text-center tracking-[1.00px] leading-[normal]">
                    Ông Trần Sĩ Long
                  </p>
                  <p className="[font-family:'Noto_Serif',Helvetica] font-normal italic text-[#67695b] text-[10px] text-center tracking-[1.00px] leading-[normal]">
                    Bà Mai Thị Huệ
                  </p>
                </div>
                <div className="text-center flex flex-col space-y-[3px]">
                  <p className="[font-family:'Noto_Sans_Tamil_UI-Bold',Helvetica] font-bold text-[#67695b] text-[9px] text-center tracking-[0.54px] leading-[normal]">
                    NHÀ GÁI
                  </p>
                  <p className="[font-family:'Noto_Serif',Helvetica] font-normal italic text-[#67695b] text-[10px] text-center tracking-[1.00px] leading-[normal]">
                    Ông Trần Quốc Bình
                  </p>
                  <p className="[font-family:'Noto_Serif',Helvetica] font-normal italic text-[#67695b] text-[10px] text-center tracking-[1.00px] leading-[normal]">
                    Bà Nguyễn Thị Minh
                  </p>
                </div>
              </div>

              <img
                className="w-full h-[218px] object-cover mt-[26px]"
                alt="Localtion"
                src="https://c.animaapp.com/D4mYxIxt/img/image-14-1@2x.png"
              />

              <a
                href="https://www.google.com/maps/place/Star+Palace+Sen+T%C3%A2y+H%E1%BB%93/@21.0734824,105.8157645,17z/data=!3m1!4b1!4m6!3m5!1s0x3135aaee13fbfa2f:0xa64c5d3176fd81eb!8m2!3d21.0734824!4d105.8157645!16s%2Fg%2F11c2jjgrsn!5m1!1e1?entry=ttu&g_ep=EgoyMDI0MTIwOS4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                className="flex space-x-2 mt-2 cursor-pointer w-full items-center justify-center px-0 py-2.5 border border-solid border-[#67695b]"
                rel="noreferrer"
              >
                <img
                  src="/assets/images/locationIcon.webp"
                  className="object-scale-down w-5 h-5 my-auto"
                  alt="location"
                />
                <span className="my-auto relative w-fit [font-family:'Noto_Serif',Helvetica] font-bold text-[#67695b] text-xs text-center tracking-[2.40px] leading-[normal]">
                  Chỉ đường
                </span>
              </a>

              <p className="mx-auto mt-[23px] [font-family:'Noto_Serif',Helvetica] font-normal italic text-[#67695b] text-[8px] text-center tracking-[1.44px] leading-[normal]">
                Rất hân hạnh được đón tiếp!
              </p>
            </div>
          </div>

          <div className="absolute w-full top-[3556px]">
            <div className="mx-6 h-fit p-5 bg-[url(https://c.animaapp.com/D4mYxIxt/img/image-8-1.png)] bg-cover bg-[50%_50%]">
              <div className="relative w-full h-fit bg-[#2c2825cc] px-3 py-10">
                <input
                  type="text"
                  placeholder="Tên của bạn"
                  className="px-3 py-4 focus:ring-0 border border-solid w-full border-[#f4efe9] [font-family:'Noto_Serif',Helvetica] font-bold placeholder-[#f4efe9] text-[#f4efe9] text-xs tracking-[1.44px] leading-[normal]"
                  style={{ background: "none" }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    });
                  }}
                />

                <div className="flex flex-col w-full items-start gap-3 mt-6">
                  <p className="relative self-stretch mt-[-1.00px] [font-family:'Noto_Serif',Helvetica] font-bold text-[#f4efe9] text-xs tracking-[1.44px] leading-[normal]">
                    Bạn sẽ tham dự chứ ?
                  </p>

                  <div className="flex flex-col items-start justify-center gap-4 px-3 py-4 relative self-stretch w-full flex-[0_0_auto] border border-solid border-[#f4efe9]">
                    <div
                      onClick={() =>
                        setFormData({
                          ...formData,
                          isAttending: true,
                        })
                      }
                      className="cursor-pointer inline-flex items-center gap-2 relative flex-[0_0_auto]"
                    >
                      <div
                        className={`relative w-4 h-4 rounded-lg border border-solid border-[#f4efe9] ${
                          !formData.isAttending ? "bg-none" : "bg-[#f4efe9]"
                        }`}
                      />

                      <div className="relative w-fit mt-[-1.00px] [font-family:'Noto_Serif',Helvetica] font-normal text-[#f4efe9] text-xs text-center tracking-[0.48px] leading-[normal]">
                        Sẽ tham dự
                      </div>
                    </div>

                    <div
                      onClick={() =>
                        setFormData({
                          ...formData,
                          isAttending: false,
                        })
                      }
                      className="cursor-pointer inline-flex items-center gap-2 relative flex-[0_0_auto]"
                    >
                      <div
                        className={`relative w-4 h-4 rounded-lg border border-solid border-[#f4efe9] ${
                          formData.isAttending ? "bg-none" : "bg-[#f4efe9]"
                        }`}
                      />

                      <p className="relative w-fit mt-[-1.00px] [font-family:'Noto_Serif',Helvetica] font-normal text-[#f4efe9] text-xs text-center tracking-[0.48px] leading-[normal]">
                        Không tham dự được mất rồi :(
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-6 flex flex-col w-full items-start gap-3">
                  <p className="relative self-stretch mt-[-1.00px] [font-family:'Noto_Serif',Helvetica] font-bold text-[#f4efe9] text-xs tracking-[1.44px] leading-[normal]">
                    Bạn là khách mời của ai ?
                  </p>

                  <div className="flex flex-col items-start w-full justify-center gap-4 px-3 py-4 relative self-stretch flex-[0_0_auto] border border-solid border-[#f4efe9]">
                    <div
                      onClick={() =>
                        setFormData({
                          ...formData,
                          isGroomGuest: true,
                        })
                      }
                      className="inline-flex items-center gap-2 relative flex-[0_0_auto] cursor-pointer"
                    >
                      <div
                        className={`relative w-4 h-4 rounded-lg border border-solid border-[#f4efe9] ${
                          typeof formData.isGroomGuest === "boolean" &&
                          formData.isGroomGuest
                            ? "bg-[#f4efe9]"
                            : "bg-none "
                        }`}
                      />

                      <div className="relative w-fit mt-[-1.00px] font-normal text-xs tracking-[0.48px] [font-family:'Noto_Serif',Helvetica] text-[#f4efe9] text-center leading-[normal]">
                        Chú rể
                      </div>
                    </div>

                    <div
                      onClick={() =>
                        setFormData({
                          ...formData,
                          isGroomGuest: false,
                        })
                      }
                      className="inline-flex items-center gap-2 relative flex-[0_0_auto] cursor-pointer"
                    >
                      <div
                        className={`relative w-4 h-4 rounded-lg border border-solid border-[#f4efe9] ${
                          typeof formData.isGroomGuest === "boolean" &&
                          !formData.isGroomGuest
                            ? "bg-[#f4efe9]"
                            : "bg-none "
                        }`}
                      />

                      <div className="relative w-fit mt-[-1.00px] font-normal text-xs tracking-[0.48px] [font-family:'Noto_Serif',Helvetica] text-[#f4efe9] text-center leading-[normal]">
                        Cô dâu
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={handleSubmitForm}
                    className="mt-8 flex w-full items-end justify-center gap-2.5 px-3 py-4 border border-solid border-[#f4efe9] cursor-pointer hover:bg-[#A3978C] duration-500"
                  >
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Noto_Serif',Helvetica] font-bold text-[#f4efe9] text-xs text-center tracking-[1.44px] leading-[normal]">
                      {submitStatus === "pending"
                        ? "Loading..."
                        : submitStatus === "success"
                        ? "Xin chân thành cảm ơn!"
                        : "Gửi xác nhận"}
                    </div>
                  </div>
                </div>
                <div className="mt-5 h-[0.5px] bg-[#F4EFE9] w-full"></div>
                <div
                  onClick={() => setIsOpenQrDialog(true)}
                  className="mt-[20.5px] flex w-full items-end justify-center gap-2.5 px-3 py-4 border border-solid border-[#f4efe9] cursor-pointer bg-[#A3978C] hover:bg-transparent duration-500"
                >
                  <div className="relative w-fit mt-[-1.00px] [font-family:'Noto_Serif',Helvetica] font-bold text-[#f4efe9] text-xs text-center tracking-[1.44px] leading-[normal]">
                    Mừng cưới
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mt-[52px] h-[100px] relative">
              <img
                className="w-full h-[100px] object-cover"
                alt="Localtion"
                src="/assets/images/footer.webp"
              />
              <p className="absolute right-1/2 translate-x-1/2 top-1/2 -translate-y-1/2 [font-family:'Alex_Brush',Helvetica] font-normal text-[#ede1d5] text-[40px] text-center tracking-[1.60px] leading-[normal]">
                Thank you!
              </p>
            </div>
            <div className="flex mt-6 w-full">
              <div className="flex space-x-1 mx-auto">
                <img
                  src="/assets/images/copyright-icon.webp"
                  alt="copyright"
                  className="h-2 w-2 my-auto"
                />
                <p className="text-[8px] font-normal leading-[10.9px] [font-family:'Noto_Serif',Helvetica] text-[#A3978C80] my-auto">
                  by Phuc Hoan - Duong Nguyen
                </p>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}

export default App;
